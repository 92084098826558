<template>
  <div class="flex items-center space-x-2 w-full">
    <div :class="searchBoxClass" v-if="showSearchBox">
      <label class="text-xs font-bold text-gray-900 mb-0 w-full">
        <span class="mb-2">{{ $t("Tìm kiếm") }}</span>
        <span class="hodo-el block">
          <el-input
            name="searchBox"
            :placeholder="$t('Nhập tên, SĐT, CCCD, hoặc QR Code')"
            v-model="form.search"
            clearable
            @input="debounceSearch"
          />
        </span>
      </label>
    </div>
    <div v-if="showQuickDate">
      <label class="text-xs font-bold text-gray-900 mb-0">
        <span class="mb-2">{{ $t("Hiển thị dữ liệu") }}</span>
        <span class="hodo-el block">
          <el-select
            name="quickDate"
            v-model="form.quickDate.value"
            :default-first-option="true"
            :placeholder="$t('Chọn hiển thị dữ liệu')"
            @change="handleUpdateDate"
          >
            <el-option
              v-for="item in form.quickDate.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
      </label>
    </div>
    <template v-if="showSelectDate">
      <div>
        <label class="text-xs font-bold text-gray-900 mb-0">
          <span class="mb-2">
            {{ isDateRange ? $t("Từ ngày") : $t("Ngày") }}
          </span>
          <span class="hodo-el block el-input el-input--suffix">
            <input
              name="fromDate"
              v-model="form.fromDate"
              type="date"
              class="el-input__inner pr-3 bg-white border border-gray-200"
              :placeholder="$t('Chọn ngày')"
              :max="maxDateValue"
              @change="handleFilters"
            />
          </span>
        </label>
      </div>
      <div v-if="!!isDateRange">
        <label class="text-xs font-bold text-gray-900 mb-0">
          <span class="mb-2">{{ $t("Đến ngày") }}</span>
          <span class="hodo-el block el-input el-input--suffix">
            <input
              name="toDate"
              v-model="form.toDate"
              type="date"
              class="el-input__inner pr-3 bg-white border border-gray-200"
              :placeholder="$t('Chọn ngày')"
              :max="maxDateValue"
              @change="handleFilters"
            />
          </span>
        </label>
      </div>
    </template>
    <div v-if="showRoom">
      <label class="text-xs font-bold text-gray-900 mb-0">
        <span class="mb-2">{{ $t("Phòng tư vấn") }}</span>
        <span class="hodo-el block">
          <el-select
            name="room"
            v-model="form.room.value"
            :default-first-option="true"
            :placeholder="$t('Chọn phòng tư vấn')"
            @change="handleFilters"
          >
            <el-option
              v-for="item in form.room.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
      </label>
    </div>
    <div v-if="showDoctor">
      <label class="text-xs font-bold text-gray-900 mb-0">
        <span class="mb-2">{{ $t("Bác sĩ") }}</span>
        <span class="hodo-el block">
          <el-select
            name="doctor"
            v-model="form.doctor.value"
            :default-first-option="true"
            :placeholder="$t('Chọn bác sĩ')"
            @change="handleFilters"
          >
            <el-option
              v-for="item in form.doctor.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
      </label>
    </div>
    <div
      v-if="form.room.value !== 'All' && $route.name !== 'WorkSchedules'"
      class="col-2 flex align-items-center mt-2"
    >
      <button
        class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
        type="button"
        @click="gotoTV"
      >
        Switch to TV Mode
      </button>
    </div>
    <div v-if="showPayment">
      <label class="text-xs font-bold text-gray-900 mb-0">
        <span class="mb-2">{{ $t("Thanh toán") }}</span>
        <span class="hodo-el block">
          <el-select
            name="payment"
            v-model="form.payment.value"
            :default-first-option="true"
            :placeholder="$t('Trạng thái thanh toán')"
            @change="handleFilters"
          >
            <el-option
              v-for="item in form.payment.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
      </label>
    </div>
    <!-- <div v-if="showStatus">
      <label class="text-xs font-bold text-gray-900 mb-0">
        <span class="mb-2">{{ $t("Trạng thái") }}</span>
        <span class="hodo-el block">
          <el-select
            name="status"
            v-model="form.status.value"
            :default-first-option="true"
            :placeholder="$t('Chọn trạng thái')"
            @change="handleFilters"
          >
            <el-option
              v-for="item in form.status.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </span>
      </label>
    </div>-->
    <ModalConnectTvMode
      v-if="formData && formData.room !== 'All'"
      ref="ModalConnectTvMode"
      :cr_id="formData && formData.room"
    ></ModalConnectTvMode>
  </div>
</template>

<style lang="css" scoped>
.hodo-el /deep/ .el-input__inner {
  background: white !important;
  border: 1px solid #dee2e6 !important;
}
</style>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ExaminationRegister from '../../pages/TVMode/ExaminationRegister.vue'
import { APPOINTMENT_STATUS, INVOICE_STATUS } from '../../utils/constants'
// import VueRouter from 'vue-router'
import ModalConnectTvMode from '../../components/PatientVisit/ModalConnectTvMode.vue'

export default {
  name: 'CustomFilters',
  components: {
    ExaminationRegister,
    ModalConnectTvMode
  },
  props: {
    searchBoxClass: {
      type: String,
      default: 'w-1/2'
    },
    isRefresh: {
      type: Boolean,
      default: false
    },
    showRoom: {
      type: Boolean,
      default: true
    },
    showPayment: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    showDoctor: {
      type: Boolean,
      default: true
    },
    showSearchBox: {
      type: Boolean,
      default: true
    },
    showSelectDate: {
      type: Boolean,
      default: true
    },
    showQuickDate: {
      type: Boolean,
      default: true
    },
    customStatusData: {
      type: Object
    },
    defaultFilters: {
      type: Object
    },
    customQuickDate: {
      type: Object
    },
    isMaxDateToday: {
      type: Boolean,
      default: false
    },
    isDateRange: {
      type: Boolean,
      default: true
    },
    doctorId: {
      type: Number
    }
  },
  data () {
    return {
      today: this.moment(),
      dataResExport: [],
      form: {
        search: '',
        quickDate: this.customQuickDate || {
          options: [
            {
              value: 0,
              label: this.$t('Hôm nay')
            },
            {
              value: 0.5,
              label: this.$t('1 tuần tiếp theo')
            },
            {
              value: 1,
              label: this.$t('1 tháng tiếp theo')
            },
            {
              value: 3,
              label: this.$t('3 tháng tiếp theo')
            }
          ],
          value: 0
        },
        fromDate: '',
        toDate: '',
        room: {
          options: [],
          value: ''
        },
        doctor: {
          options: [],
          value: ''
        },
        payment: {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            },
            {
              value: INVOICE_STATUS.CODE.PATIENT_PENDING,
              label: this.$t('Chưa thanh toán')
            },
            {
              value: INVOICE_STATUS.CODE.PATIENT_PAID,
              label: this.$t('Chờ xác nhận')
            },
            {
              value: INVOICE_STATUS.CODE.DOCTOR_ACCEPT,
              label: this.$t('Đã thanh toán')
            }
          ],
          value: 'All'
        },
        status: this.customStatusData || {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            },
            {
              value: APPOINTMENT_STATUS.CODE.CLINIC_CONFIRMED,
              label: this.$t('Đã xác nhận')
            },
            {
              value: APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
              label: this.$t('Chờ xác nhận')
            },
            {
              value: APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED,
              label: this.$t('Đã hủy')
            }
          ],
          value: 'All'
        }
      },
      formData: null,
      typing: false,
      debounce: null
    }
  },
  async created () {
    await this.getConsultingRooms({
      limit: 100,
      params: {
        clinic_id: this.$clinicId
      }
    })
    await this.getClinicDoctors({ limit: 100 })
    if (this.defaultFilters?.doctor) {
      this.form = {
        ...this.form,
        doctor: {
          ...this.form.doctor,
          value: this.defaultFilters?.doctor
        }
      }
    }

    if (this.defaultFilters?.room) {
      this.form = {
        ...this.form,
        room: {
          ...this.form.room,
          value: this.defaultFilters?.room
        }
      }
    }

    if (this.defaultFilters?.fromDate && this.defaultFilters?.toDate) {
      this.form = {
        ...this.form,
        fromDate: this.defaultFilters?.fromDate,
        toDate: this.defaultFilters?.toDate
      }
    }

    if (this.doctorId) {
      this.form = {
        ...this.form,
        doctor: {
          ...this.form.doctor,
          value: this.doctorId
        }
      }
    }
  },
  watch: {
    // quickDate (value) {
    //   console.log(value)
    //   if (value) {
    //     this.form = {
    //       ...this.form,
    //       toDate: this.moment().add(value, 'months').format('YYYY-MM-DD')
    //     }
    //   } else {
    //     this.form = {
    //       ...this.form,
    //       toDate: this.moment().format('YYYY-MM-DD')
    //     }
    //   }
    // },
    customStatusData (data) {
      if (data) {
        this.form = {
          ...this.form,
          status: {
            ...this.form.status,
            value: data.value
          }
        }
      }
    },
    isRefresh (value) {
      if (value) {
        this.form = {
          ...this.form,
          search: '',
          fromDate: this.moment().format('YYYY-MM-DD'),
          toDate: this.moment().format('YYYY-MM-DD'),
          room: {
            ...this.form.room,
            value: 'All'
          },
          doctor: {
            ...this.form.doctor,
            value: 'All'
          },
          payment: {
            ...this.form.payment,
            value: 'All'
          },
          status: {
            ...this.form.status,
            value: this.customStatusData?.value || 'All'
          },
          quickDate: {
            ...this.form.quickDate,
            value: 0
          }
        }
      }
    },
    consultingRooms (data) {
      if (data) {
        this.form.room = {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            }
          ],
          value: 'All'
        }
        _.forEach(data, (item) => {
          this.form.room.options.push({
            value: item?.id,
            label: item?.name
          })
        })
      }
    },
    clinicDoctors (data) {
      if (data) {
        this.form.doctor = {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            }
          ],
          value: 'All'
        }
        _.forEach(data, (item) => {
          this.form.doctor.options.push({
            value: item?.doctor_id,
            label: item?.doctor?.name
          })
        })
      }
    },
    defaultFilters (data) {
      console.log(data)
      if (data) {
        this.form = {
          ...this.form,
          doctor: {
            ...this.form.doctor,
            value: data.doctor
          },
          room: {
            ...this.form.room,
            value: data.room
          }
        }
      }
    }
  },
  mounted () {
    this.form.fromDate = this.moment().format('YYYY-MM-DD')
    this.form.toDate = this.moment().format('YYYY-MM-DD')
    if (this.doctorId && this.$route.name !== 'WorkSchedules') {
      this.form.quickDate.value = 0.5
      this.form.toDate = this.moment().add(1, 'weeks').format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters({
      consultingRooms: 'consultingRoomStore/allConsultingRooms',
      consultingRoomMeta: 'consultingRoomStore/allMeta',
      clinicDoctors: 'clinicDoctorStore/allClinicDoctors',
      clinicDoctorMeta: 'clinicDoctorStore/allMeta'
    }),
    maxDateValue () {
      return this.isMaxDateToday ? new Date().toISOString().split('T')[0] : ''
    }
  },
  methods: {
    ...mapActions('consultingRoomStore', [
      'getConsultingRoomsRequest',
      'getConsultingRoomsByDoctorID'
    ]),
    ...mapActions('clinicDoctorStore', ['getClinicDoctorsRequest']),
    handleFilters () {
      console.log(this.form.room.value)
      this.formData = {
        search: this.form.search,
        fromDate: this.form.fromDate,
        toDate: this.isDateRange ? this.form.toDate : this.form.fromDate,
        room: this.form.room.value,
        payment: this.form.payment.value,
        status: this.form.status.value,
        doctor: this.form.doctor.value
      }

      this.$emit('setFilters', this.formData)
    },
    resetDate () {
      this.form.fromDate = this.moment().format('YYYY-MM-DD')
      this.form.toDate = this.moment().format('YYYY-MM-DD')
    },
    handleUpdateDate (value) {
      this.resetDate()

      if (value) {
        if (value === 0.5) {
          // value = 0.5 => add 1 week
          this.form = {
            ...this.form,
            toDate: this.moment().add(1, 'weeks').format('YYYY-MM-DD')
          }
        } else {
          this.form = {
            ...this.form,
            toDate: this.moment().add(value, 'months').format('YYYY-MM-DD')
          }
        }
      } else {
        this.form = {
          ...this.form,
          toDate: this.moment().format('YYYY-MM-DD')
        }
      }

      this.handleFilters()
    },
    gotoTV () {
      // window.open(`/#/tv-mode?cr_id=${this.form.room.value}`, '_blank')
      this.showModalConnectTvMode()
    },
    showModalConnectTvMode () {
      this.$refs.ModalConnectTvMode.openDialog(true)
    },
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.formData = {
          ...this.formData,
          search: this.form.search
        }
        this.$emit('setFilters', this.formData)
      }, 600)
    },
    async getConsultingRooms ({ limit = 100, params = {} }) {
      try {
        let paramsData = {
          ...params,
          limit
        }
        if (this.doctorId) {
          await this.getConsultingRoomsByDoctorID({
            id: this.doctorId,
            params: { limit: 100 }
          })
        } else {
          await this.getConsultingRoomsRequest(paramsData)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getClinicDoctors ({ limit = 100, params = {} }) {
      try {
        let paramsData = {
          ...params,
          limit
        }

        await this.getClinicDoctorsRequest(paramsData)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
