<template>
  <div
    class="flex flex-col fixed inset-0 z-2000 transition-all duration-300"
    :class="showModal ? 'visible opacity-100' : 'invisible opacity-0'"
  >
    <div class="absolute inset-0 hidden lg:block bg-gray-700 opacity-25" @click="hideModal" />

    <div
      class="absolute top-0 bg-white flex flex-col flex-1 h-full ml-auto transition-all duration-300"
      :class="`${showModal ? 'visible opacity-100 right-0' : 'invisible opacity-0 -right-24'} ${size}`"
      style="box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%)"
    >
      <div class="flex justify-between w-full px-4 pt-4">
        <h2 class="mb-0" :class="titleClass" v-if="title.length">
          <span>{{ title }}</span>
          <span class="ml-2" v-if="count">({{ count }})</span>
        </h2>
        <div class="flex items-center cursor-pointer ml-auto" @click="hideModal">
          <div class="mt-0.5 leading-none">{{ $t('Đóng') }}</div>
          <div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-1"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1">
        <div style="height: calc(100vh - 60px)">
          <div class="p-4 overflow-x-hidden overflow-y-auto h-full">
            <div class="w-full">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    escClose: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    title: {
      type: String
    },
    titleClass: {
      type: String
    },
    size: {
      type: String,
      default: 'w-5/6'
    }
  },
  data () {
    return {}
  },
  created () {
    if (this.escClose) {
      this.escapeEvent()
    }
  },
  destroyed () {
    this.cancelEscapeEvent()
  },
  methods: {
    hideModal () {
      this.$emit('hideModal')
    },
    escapeEvent () {
      const self = this
      document.addEventListener('keydown', function (evt) {
        if (evt.key === 'Escape') {
          self.hideModal()
        }
      })
    },
    cancelEscapeEvent () {
      const self = this
      document.removeEventListener('keydown', self.escapeEvent)
    }
  }
}
</script>
