<template>
  <div class="flex justify-end items-center w-full">
    <div>
      <b-pagination aria-controls="schedule-table" v-model="pagination.currentPage" :total-rows="totalPages"
        :per-page="pagination.perPage" size="sm" align="right" first-number last-number class="hodo-schedule-table m-0"
        prev-class="prev-item mx-1" next-class="next-item mx-1" :page-class="'mx-1'" :ellipsis-class="'mx-1'">
        <template #page="{ page, active }" class="mx-2">
          <span :class="active ? 'text-white' : 'text-gray-600'">{{ page }}</span>
        </template>
        <template #prev-text>
          <i class="el-icon-arrow-left text-gray-600" />
        </template>
        <template #next-text>
          <i class="el-icon-arrow-right text-gray-600" />
        </template>
      </b-pagination>
    </div>
    <div class="ml-1">
      <el-dropdown>
        <span class="block py-1.5 px-2 rounded-lg border bg-white text-gray-900 text-sm" role="button">
          <span>{{ pagination.perPage }}</span>
          <span class="mx-1">/</span>
          <span>{{ $t('Trang') }}</span>
          <i class="el-icon-caret-bottom" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in listShowRecord" :key="index">
            <div @click="handleSelectedShowRecord(item)">
              <span>{{ item }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomPagination',
  props: ['pagination, totalPages'],
  data () {
    return {
      listShowRecord: [10, 20, 50, 100],
      perPage: this.pagination.perPage || 10,
      currentPage: this.pagination.currentPage || 1,
      rows: this.totalPages || 1
    }
  },
  methods: {
    handleSelectedShowRecord (value) {
      this.perPage = value
    }
  }
}
</script>
